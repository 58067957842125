<template>
  <v-container>
    <v-row class="mt-10" v-if="!edit_loader">
      <v-col v-for="(a, i) in 12" :key="i" cols="6" md="3">
        <v-skeleton-loader type="card-heading"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-card v-if="edit_loader" elevation="0" class="mt-10 mb-10">
      <v-card-title class="font-weight-bold mb-2">تفاصيل المنتج</v-card-title>
      <v-form ref="form">
        <v-tabs class="mb-3" v-model="tabs" background-color="gray">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-model="tabs"
            v-for="(lang, i) in $store.getters.langs"
            :key="i"
          >
            {{ lang.lang_name }}
          </v-tab>
        </v-tabs>
        <!--/tabs -->
        <v-tabs-items v-model="tabs">
          <v-tab-item v-for="(lang, i) in $store.getters.langs" :key="i">
            <v-row>
              <v-col cols="6" md="3">
                <v-text-field
                  :rules="tabs == 0 ? [(v) => !!v || 'حقل مطلوب'] : []"
                  solo
                  hide-details
                  v-model="product.meal_name[lang.locale]"
                  :disabled="is_admin"
                >
                  <template slot="label">
                    <strong v-if="tabs == 0" class="red--text">*</strong>
                    اسم الموجبة"
                  </template>
                </v-text-field>
                <p v-if="errors.name && tabs == 0" class="red--text">
                  الاسم مطلوب
                </p>
              </v-col>
              <!--/ col -->
              <v-col cols="6" md="3" v-if="tabs == 0">
                <v-select
                  v-if="!is_admin"
                  :rules="tabs == 0 ? [(v) => !!v || 'حقل مطلوب'] : []"
                  v-model="product.category"
                  :items="categories"
                  item-value="id"
                  item-text="category_name"
                  solo
                  hide-details
                  :loading="loading_cat"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    قسم الوجبة
                  </template>
                </v-select>

                <v-text-field
                  v-if="is_admin"
                  :rules="tabs == 0 ? [(v) => !!v || 'حقل مطلوب'] : []"
                  solo
                  :value="$route.query.category_name"
                  :disabled="is_admin"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    القسم
                  </template>
                </v-text-field>
                <p v-if="errors.category && tabs == 0" class="red--text">
                  القسم مطلوب
                </p>
              </v-col>
              <!--/ col -->
              <v-col cols="12" md="3">
                <v-textarea
                  :disabled="is_admin"
                  rows="1"
                  solo
                  v-model="product.description[lang.locale]"
                >
                  <template slot="label"> وصف المنتج </template>
                </v-textarea>
              </v-col>
              <!--/ col -->

              <v-col cols="12" md="3">
                <v-select
                  :disabled="is_admin"
                  rows="1"
                  solo
                  :items="pro_status"
                  item-text="text"
                  item-value="value"
                  v-model="product.status"
                >
                  <template slot="label">حالة المنتج</template>
                </v-select>
              </v-col>
              <!--/ col -->

              <v-col cols="12" md="3">
                <v-text-field
                  :disabled="is_admin"
                  rows="1"
                  solo
                  item-text="text"
                  item-value="value"
                  type="number"
                  v-model="product.sort"
                  label="الترتيب"
                >
                </v-text-field>
              </v-col>
              <!--/ col -->
            </v-row>
          </v-tab-item>
        </v-tabs-items>

        <v-row>
          <v-col md="6" cols="12">
            <h3 v-if="!is_admin">نوع التسعيرة</h3>
            <div class="mb-10 custom_swtich" v-if="!is_admin">
              <v-switch
                v-model="multiple_price"
                class="mt-4 per"
                hide-details
                x-large
              ></v-switch>
            </div>

            <v-col cols="12" sm="6" class="px-0" v-if="!multiple_price">
              <v-row class="pb-4">
                <v-col cols="6">
                  <v-text-field
                    solo
                    v-model.number="product.price"
                    type="number"
                    min="0"
                    :disabled="is_admin"
                    hide-details
                  >
                    <template slot="label"> السعر </template>
                  </v-text-field>

                  <!--/ price -->
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    solo
                    v-model.number="product.sale"
                    type="number"
                    min="0"
                    :disabled="is_admin"
                    :rules="[(v) => !check_sale || 'اكبر من السعر']"
                  >
                    <template slot="label"> التخفيض </template>
                  </v-text-field>

                  <!--/ price -->
                </v-col>
              </v-row>
            </v-col>

            <div v-if="multiple_price">
              <v-tabs class="mb-3" v-model="tabs2" background-color="gray">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab
                  v-model="tabs2"
                  v-for="(lang, i) in $store.getters.langs"
                  :key="i"
                >
                  {{ lang.lang_name }}
                </v-tab>
              </v-tabs>
              <!--/tabs -->

              <v-tabs-items v-model="tabs2">
                <v-tab-item v-for="(lang, i) in $store.getters.langs" :key="i">
                  <v-row
                    v-for="(a, i) in product.more_prices"
                    :key="i"
                    class="align-center pb-4"
                  >
                    <v-col cols="4" class="py-0">
                      <v-text-field
                        :disabled="is_admin"
                        solo
                        v-model="product.more_prices[i].name[lang.locale]"
                      >
                        <template slot="label">
                          <strong v-if="tabs2 == 0" class="red--text">*</strong>
                          النوع
                        </template>
                      </v-text-field>
                      <p class="red--text" v-if="tabs2 == 0 && a.name_err">
                        النوع مطلوب
                      </p>
                    </v-col>
                    <!--/ col -->
                    <v-col cols="3" class="py-0" v-if="tabs2 == 0">
                      <v-text-field
                        solo
                        type="number"
                        min="0"
                        :disabled="is_admin"
                        maxlength="10"
                        v-model.number="product.more_prices[i].price"
                      >
                        <template slot="label"> السعر </template>
                      </v-text-field>
                    </v-col>
                    <!--/ col -->

                    <v-col cols="3" class="py-0" v-if="tabs2 == 0">
                      <v-text-field
                        solo
                        type="number"
                        min="0"
                        :disabled="is_admin"
                        :rules="[
                          (v) =>
                            product.more_prices[i].sale <
                              product.more_prices[i].price ||
                            'اكبر او يساوي السعر',
                        ]"
                        v-model.number="product.more_prices[i].sale"
                      >
                        <template slot="label"> التخفيض </template>
                      </v-text-field>
                    </v-col>
                    <!--/ col -->

                    <v-col cols="2" class="py-0" v-if="!is_admin">
                      <v-btn
                        @click="delete_price(product.more_prices[i], i)"
                        icon
                        color="error"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-col>
                    <!--/ col -->
                  </v-row>
                  <!--/ row -->
                </v-tab-item>
              </v-tabs-items>

              <v-btn
                v-if="!is_admin"
                class="mt-4"
                @click="
                  product.more_prices.push({
                    name: {},
                    price: '',
                    name_err: false,
                  })
                "
                color="primary"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </div>
          </v-col>

          <!--/ col (prices) -->

          <v-col md="6" cols="12">
            <h3 class="mb-4">صور الوجبة</h3>

            <v-alert
              v-if="is_admin && product.uploaded_images.length == 0"
              type="warning"
              dense
              class="text-center"
              outlined
            >
              <small> لايوجد اى صور للمنتج </small>
            </v-alert>
            <v-text-field
              @click="dialog = true"
              readonly
              v-if="$route.params.id && product.uploaded_images.length != 0"
              :value="`عدد صور المنتج الحالية (${product.uploaded_images.length})`"
            >
              <template slot="append-outer">
                <v-btn
                  @click="dialog = true"
                  elevation="1"
                  color="blue darken"
                  class="white--text"
                  small
                  fab
                >
                  <v-icon>visibility</v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <div v-if="!is_admin">
              <v-row
                class="align-center"
                v-for="(img, i) in product.images"
                :key="i"
              >
                <v-col cols="10" sm="11">
                  <v-file-input
                    class="mb-5"
                    hide-details
                    prepend-icon=""
                    prepend-inner-icon="image"
                    solo
                    v-model="product.images[i]"
                  >
                    <template slot="label"> اختيار الصورة </template>
                  </v-file-input>
                </v-col>
                <v-col cols="2" sm="1">
                  <v-btn
                    @click="product.images.splice(i, 1)"
                    icon
                    color="error"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-btn
              v-if="!is_admin"
              @click="product.images.push(null)"
              color="primary"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-col>
          <!--/ col -->
          <v-col cols="12" v-if="$store.getters.permissions.includes('orders')">
            <h3 class="mb-4">ملاحظات الوجبة</h3>
            <v-combobox
              v-model="product.notes"
              :items="notes"
              label="الملاحظات"
              multiple
              chips
              solo
              :search-input.sync="search"
            >
              <template v-slot:item="{ item }">
                {{ item.note_name[0].name }}
              </template>
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-if="item === Object(item)"
                  v-bind="attrs"
                  :input-value="selected"
                  label
                  small
                >
                  <span class="pr-2">
                    {{ item.note_name[0].name }}
                  </span>
                  <v-icon small @click="parent.selectItem(item)">
                    $delete
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <v-col
            cols="12"
            v-if="$store.getters.permissions.includes('additions')"
          >
            <h3 class="mb-4">المكونات الشاملة</h3>

            <v-combobox
              v-model="product.free_additions"
              :items="additions"
              label="الآضافات"
              solo
              multiple
              chips
              :search-input.sync="search"
            >
              <template v-slot:item="{ item }">
                {{ item.add_name[0].name }}
              </template>
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-if="item === Object(item)"
                  v-bind="attrs"
                  :input-value="selected"
                  label
                  small
                >
                  <span class="pr-2">
                    {{ item.add_name[0].name }}
                  </span>
                  <v-icon small @click="parent.selectItem(item)">
                    $delete
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <!--/ col -->

          <v-col
            cols="12"
            v-if="$store.getters.permissions.includes('additions')"
          >
            <h3 class="mb-4">المكونات</h3>
            <div v-for="(add, x) in product.additions" :key="x">
              <v-tabs class="mb-3" v-model="add.tabs" background-color="gray">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab
                  v-model="add.tabs"
                  v-for="(lang, i) in $store.getters.langs"
                  :key="i"
                >
                  {{ lang.lang_name }}
                </v-tab>
              </v-tabs>
              <!--/tabs -->

              <v-tabs-items v-model="add.tabs">
                <v-tab-item v-for="(lang, i) in $store.getters.langs" :key="i">
                  <v-row class="pb-2">
                    <v-col cols="12" md="4">
                      <v-text-field
                        hide-details
                        v-model="add.description[lang.locale]"
                        solo
                      >
                        <template slot="label">
                          <strong class="red--text" v-if="add.tabs == 0"
                            >*</strong
                          >
                          وصف المجموعة
                        </template>
                      </v-text-field>
                      <p v-if="add.name_err && add.tabs == 0" class="red--text">
                        الوصف مطلوب
                      </p>
                    </v-col>

                    <!--/ description -->
                    <v-col cols="12" md="3" v-if="add.tabs == 0">
                      <v-text-field
                        v-model="add.maximum"
                        label="الكمية"
                        solo
                        :readonly="!add.is_max"
                      >
                        <template slot="append-outer">
                          <v-checkbox
                            @change="is_max(i)"
                            v-model="add.is_max"
                            class="check_box"
                          ></v-checkbox>
                        </template>
                      </v-text-field>
                    </v-col>
                    <!--/ col -->
                    <v-col cols="9" md="2" v-if="add.tabs == 0">
                      <v-text-field
                        :value="add.is_free ? 'نعم' : 'لا'"
                        label="مجانا"
                        readonly
                        solo
                      >
                        <template slot="append-outer">
                          <v-checkbox
                            v-model="add.is_free"
                            class="check_box"
                          ></v-checkbox>
                        </template>
                      </v-text-field>
                    </v-col>
                    <!--/ col -->

                    <v-col cols="9" md="2" v-if="add.tabs == 0">
                      <v-checkbox
                        v-model="add.quantity"
                        class="mt-5"
                        label="كمية الأضافات"
                      ></v-checkbox>
                    </v-col>
                    <!--/ col -->
                    <v-col cols="2" v-if="add.tabs == 0">
                      <v-btn
                        @click="delete_group(add.id, x)"
                        class="mt-5"
                        icon
                        color="red"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" v-if="add.tabs == 0">
                      <v-combobox
                        v-model="add.adds"
                        :items="additions"
                        solo
                        label="الآضافات"
                        multiple
                        item-value="id"
                        chips
                      >
                        <template v-slot:item="{ item }">
                          {{ item.add_name[0].name }}
                        </template>
                        <template
                          v-slot:selection="{ attrs, item, parent, selected }"
                        >
                          <v-chip
                            v-if="item === Object(item)"
                            v-bind="attrs"
                            :input-value="selected"
                            label
                            small
                          >
                            <span class="pr-2">
                              {{ item.add_name[0].name }}
                            </span>
                            <v-icon small @click="parent.selectItem(item)">
                              $delete
                            </v-icon>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                  <!--/ row -->
                </v-tab-item>
              </v-tabs-items>
            </div>
            <div class="text-end">
              <v-btn @click="add_new_additions()" outlined>مجموعة جديدة</v-btn>
            </div>
          </v-col>

          <v-col cols="12" class="mb-10" v-if="!is_admin">
            <v-btn
              @click="save()"
              :disabled="save_loader"
              :loading="save_loader"
              color="primary"
              width="280"
              height="60"
              class="save"
              >حفظ</v-btn
            >
          </v-col>
        </v-row>
        <!--/ row -->
      </v-form>
      <!-- / form -->
    </v-card>

    <v-dialog max-width="600" v-model="dialog">
      <v-card :disabled="delete_loading" :loading="delete_loading">
        <v-card-title>
          <v-btn @click="dialog = false" large icon>
            <v-icon large>close</v-icon>
          </v-btn>
        </v-card-title>
        <!--/ card title -->
        <v-card-text>
          <v-carousel hide-delimiters v-model="carousel">
            <v-carousel-item
              class="text-center"
              v-for="(image, i) in product.uploaded_images"
              :key="i"
            >
              <img
                style="width: 80%"
                :src="`${$store.state.def_path}/${image.image}`"
              />
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
        <!--/ card text -->
        <v-divider></v-divider>
        <v-card-actions v-if="!is_admin" class="pa-4">
          <v-btn @click="delete_image()" color="error">حذف الصورة</v-btn>
        </v-card-actions>
      </v-card>
      <!--/ card -->
    </v-dialog>
    <!-- dialog uploaded images -->

    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حفظ المنتج بنجاح</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-snackbar color="error" left v-model="snackbar_error">
      <p>برجاء مراجعة البيانات</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">cancel</v-icon>
      </template>
    </v-snackbar>
    <!--/ card -->
  </v-container>
</template>

<script>
export default {
  name: "product",
  data() {
    return {
      search: null,
      additions: ["Programming", "Design", "Vue", "Vuetify"],
      tabs: 0,
      tabs2: 0,
      delete_loading: false,
      edit_loader: true,
      carousel: 0,
      dialog: false,
      snackbar_error: false,
      snackbar_success: false,
      loading_cat: true,
      multiple_price: false,
      categories: [],
      save_loader: false,
      errors: {
        name: false,
        category: false,
        price: false,
      },
      notes: [],
      product: {
        free_additions: [],
        additions: [],
        uploaded_images: [],
        price: 0,
        meal_name: {},
        category: null,
        description: {},
        images: [null],
        notes: [],
        sale: 0,
        status: 1,
        sort: null,
        more_prices: [
          {
            name: {},
            price: 0,
            sale: 0,
            name_err: false,
          },
        ],
      },
      pro_status: [
        {
          text: "متوفر",
          value: 1,
        },
        {
          text: "اخفاء",
          value: 0,
        },
        {
          text: "غير متوفر",
          value: 2,
        },
      ],
    };
  },
  watch: {
    multiple_price(newVal) {
      if (newVal) {
        this.product.price = 0;
        this.product.sale = 0;
      }
    },
  },
  computed: {
    is_admin() {
      return this.$store.state.user_menu_dashboard.is_admin == 1 ? true : false;
    },
    check_sale() {
      return this.product.sale > this.product.price;
    },
  },
  methods: {
    get_notes() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "notes",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.notes = Object.assign([], res.data.data);
        });
    },
    get_additions() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "additions",
            method: "get",
          },
        })
        .then((res) => {
          this.additions = Object.assign([], res.data.data);
        });
    },
    get_categories() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "Categories",
            method: "get",
          },
        })
        .then((res) => {
          let data = res.data.data;
          data.forEach((e, i) => {
            this.categories.push({
              category_name: e.category_name[0].name,
              id: e.id,
            });
          });
          this.loading_cat = false;
        });
    },
    validate() {
      let def_locale = this.$store.getters.langs[0].locale;
      const err = [];

      if (!this.product.meal_name[def_locale]) {
        err.push("name");
        this.errors.name = true;
      } else {
        this.errors.name = false;
      }

      if (!this.product.category) {
        err.push("category");
        this.errors.category = true;
      } else {
        this.errors.category = false;
      }

      if (this.multiple_price) {
        this.product.more_prices.forEach((e, i) => {
          if (!e.name[def_locale]) {
            this.product.more_prices[i].name_err = true;
            err.push(i);
          } else {
            this.product.more_prices[i].name_err = false;
          }
        });
      }

      if (this.product.additions.length != 0) {
        this.product.additions.forEach((e, i) => {
          if (!e.description[def_locale]) {
            this.product.additions[i].name_err = true;
            err.push(i);
          } else {
            this.product.additions[i].name_err = false;
          }
        });
      }

      if (err.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    save() {
      if (this.validate() && !this.save_loader && !this.is_admin) {
        this.save_loader = true;
        const formData = new FormData();
        Object.entries(this.product).forEach((e, i) => {
          if (
            e[0] != "images" &&
            e[0] != "more_prices" &&
            e[0] != "meal_name" &&
            e[0] != "description" &&
            e[0] != "free_additions" &&
            e[0] != "additions" &&
            e[0] != "notes"
          ) {
            formData.append(e[0], e[1]);
          } else if (e[0] == "meal_name") {
            Object.entries(this.product.meal_name).forEach((s) => {
              formData.append(`meal_name[${s[0]}]`, s[1]);
            });
          } else if (e[0] == "description") {
            Object.entries(this.product.description).forEach((s) => {
              formData.append(`description[${s[0]}]`, s[1]);
            });
          } else if (e[0] == "free_additions") {
            Object.entries(this.product.free_additions).forEach((s) => {
              formData.append(`free_additions[${s[0]}]`, s[1].id);
            });
          } else if (
            e[0] == "additions" &&
            this.$store.getters.permissions.includes("additions")
          ) {
            Object.entries(this.product.additions).forEach((s) => {
              formData.append(`additions[${s[0]}]`, JSON.stringify(s[1]));
            });
          } else if (
            e[0] == "notes" &&
            this.$store.getters.permissions.includes("orders")
          ) {
            Object.entries(this.product.notes).forEach((s) => {
              formData.append(`notes[${s[0]}]`, s[1].id);
            });
          }
        });

        if (this.product.images) {
          this.product.images.forEach((e, i) => {
            if (e) {
              formData.append(`images[${i}]`, e);
            }
          });
        }

        if (this.product.more_prices) {
          this.product.more_prices.forEach((e, i) => {
            if (e.type != "" && e.price != "") {
              Object.entries(e).forEach((s) => {
                if (s[0] != "name") {
                  formData.append(`more_prices[${i}][${s[0]}]`, s[1]);
                } else {
                  Object.entries(e.name).forEach((e) => {
                    formData.append(
                      `more_prices[${i}][${s[0]}][${e[0]}]`,
                      e[1]
                    );
                  });
                }
              });
            }
          });
        }
        this.$store
          .dispatch("public__request", {
            config: {
              url: this.$route.params.id
                ? `Products/${this.$route.params.id}`
                : "Products",
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            this.snackbar_success = true;
            this.save_loader = false;
            this.$router.push("/Products");
          })
          .catch((err) => {
            this.snackbar_error = true;
            this.save_loader = false;
          });
      } else {
        this.snackbar_error = true;
      }
    },
    edit_product() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: this.is_admin
              ? `ProductDetails/${this.$route.params.id}`
              : `Products/${this.$route.params.id}`,
            method: "get",
          },
        })
        .then((res) => {
          this.product = Object.assign({}, res.data.data);
          if (res.data.data.more_prices.length != 0) {
            this.multiple_price = true;
            this.product.more_prices.forEach((e, i) => {
              this.product.more_prices[i].name_err = false;
            });
          }
          this.edit_loader = true;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    delete_image() {
      let img_id = this.product.uploaded_images[this.carousel].id;
      this.delete_loading = true;
      if (!this.is_admin) {
        this.$store
          .dispatch("public__request", {
            config: {
              url: `Image/${img_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.product.uploaded_images.splice(this.carousel, 1);
            this.delete_loading = false;
            if (this.product.uploaded_images.length == 0) {
              this.dialog = false;
            }
          });
      }
    },
    delete_price(price, index) {
      if (price.id && !this.is_admin) {
        this.$store.dispatch("public__request", {
          config: {
            url: `Price/${price.id}`,
            method: "delete",
          },
        });
      }
      this.product.more_prices.splice(index, 1);
    },
    add_new_additions() {
      let add_index = this.product.additions.length;
      const addition = {
        description: {},
        maximum: 0,
        is_free: false,
        is_max: false,
        tabs: 0,
        adds: [],
        name_err: false,
        quantity: false,
      };
      this.$set(this.product.additions, add_index, addition);
    },
    is_max(index) {
      this.$set(this.product.additions[index], "quantity", 0);
    },
    delete_group(id, index) {
      if (id) {
        this.$store
          .dispatch("public__request", {
            config: {
              url: `delete_group/${id}`,
              method: "delete",
            },
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
      this.product.additions.splice(index, 1);
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.edit_loader = false;
      this.edit_product();
    }
    if (this.$store.state.user_menu_dashboard.is_admin != 1) {
      this.get_categories();
      this.get_additions();
      this.get_notes();
    }
  },
};
</script>

<style lang="scss" scoped>
.save {
  font-size: 20px;
}

::v-deep {
  .theme--light.v-input--is-disabled input,
  .theme--light.v-input--is-disabled textarea {
    color: #202020 !important;
  }
  .v-responsive {
    align-items: center;
  }
  .v-input--switch__thumb {
    width: 30px;
    height: 30px;
    right: -14px;
  }
  .v-input--switch .v-input--selection-controls__input {
    width: 58px;
    height: 29px;
  }
  .v-input--switch__track {
    width: 200px;
    height: 18px;
    position: absolute;
    opacity: 0.6;
    right: -11px;
    top: calc(50% - 5px);
    border-radius: 50px;
    background-color: #b7ecbf;
  }
  .per {
    .v-input--switch__track {
      width: 110px;
      height: 30px;
      position: absolute;
      opacity: 1;
      right: -11px;
      top: calc(50% - 9px);
      &::after {
        content: "سعر واحد";
        float: left;
        margin-left: 12px;
        font-weight: bold;
        font-size: 13px;
        margin-top: 4px;
        color: #000;
        display: block;
      }
      &::before {
        content: "اكثر من سعر";
        color: #000;
        float: right;
        font-weight: bold;
        font-size: 13px;
        margin-right: 12px;
        margin-left: 4px;
        margin-top: 4px;
        display: none;
      }
      &.primary--text {
        &::before {
          display: block !important;
        }
        &::after {
          display: none !important;
        }
      }
    }
  }
  .custom_swtich {
    .v-input--selection-controls__ripple {
      top: calc(50% - 22px) !important;
      right: -27px !important;
      width: 40px;
      height: 40px;
    }

    .v-input--is-label-active {
      .v-input--switch__thumb {
        transform: translate(-90px, 0) !important;
      }
      .v-input--selection-controls__ripple {
        right: 45px !important;
      }
      .v-input--switch__track {
        background-color: #bbebef;
      }
    }
  }
  .check_box {
    position: relative;
    top: -10px;
  }
}
</style>
